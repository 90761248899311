<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import SystemValues from "@/components/system-values";
import { required } from "vuelidate/lib/validators";
import { parseErrors } from '../../../helpers'
import Swal from "sweetalert2";
import http from '@/http'
import { debounce } from "debounce"
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import {
   
} from "@/state/helpers";

export default {
  components: {
    Layout,
    PageHeader,
    SystemValues,
  
    VueTypeaheadBootstrap
  },
  data() {
    
    return {
      title: this.$t("pages.products.title"),
      showmodal: false,
      editedItemId: null,
      submitted: false,
      editedItem: {
        name:null,
        displayName:null,
        description: null
      },
      items:[],
      selectedCurrency: null,
      selectedExchange: null,
      instrumentName: null,
      instrumentQuery: null,
      currencies:[],
      exchanges: [],
      instrumentsLoading: false,
      keysLoading: false,
      instruments:[],
      errors:[],
      isBusy: false,
      keyword: null,
      selectedKey: null,
      saving: null,
      keys:[],
      selectedKeyValue: null,
      name: null,
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
      breadcrumbs: [
        
        {
          text: this.$t("pages.products.title"),
          active: true
        }
      ]
    };
  },
  validations: {
    editedItem: {
      displayName: { required },
      name: { required },
      currency: { required },
      exchange: { required },
      instrument: { required }
    }
  },
  created() {
    this.loadExchanges();
    this.loadCurrencies();
    this.loadKeys();
    this.load();
  },
  watch: {
    currentPage: {
      handler: function () {
        this.load();
      }
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    }
  },
  methods: {
    limitText (count) {
      return `and ${count} other instrument`
    },
    debounceSearchInstrument: debounce(function () {
      this.searchInstrument();
    }, 500),
    async searchInstrument () {
       this.instrumentsLoading = true;
        const resp = await http.get(`api/instruments?`,{
          params:{
            q: this.instrumentQuery,
            limit: 10
          }
        });
        this.instruments = resp.data.map(a => { return {
            id: a.id,
            type: a.type,
            name: a.name + ' [' + a.type + ']'
          };
        });
        this.instrumentsLoading = false;
    },
    clearInstrument () {
      this.editedItem.instrument = null;
      this.instrumentQuery = null;
    },
    async loadCurrencies(){
      this.selectedKeyValue = null;
      this.selectedKey = null;
      const resp = await http.get(`api/currencies`);
      this.currencies = resp.data;
    },
    async loadExchanges(){
      this.selectedKeyValue = null;
      this.selectedKey = null;
      const resp = await http.get(`api/exchanges`);
      this.exchanges = resp.data;
    },
     async applyFilters(){
      this.currentPage = 1;
      this.load();
    },
    async loadKeys(){
       this.keysLoading = true;
      this.selectedKeyValue = null;
      this.selectedKey = null;
      const resp = await http.get(`api/products/system-values/keys`);
      this.keys = resp.data;
       this.keysLoading = false;
    },
    async load()  {
      this.isBusy = true;
    
      let sv = {};
      if(this.selectedKey){
        sv[this.selectedKey] = this.selectedKeyValue;
      }
      const resp = await http.post(`api/products/search`, {
        take: this.perPage,
        skip: this.perPage * (this.currentPage - 1),
        keyword:  this.keyword,
        name: this.name,
        systemValues:sv,
        currency: { id: this.selectedCurrency },
        exchange: { id: this.selectedExchange },
        instrument: { name: this.instrumentName}
      });
      this.items = resp.data.items;
      this.totalItems = resp.data.total;
      this.isBusy = false;  
    },
    
    hideModal() {
      this.processing = false;
      this.showmodal = false;
      this.submitted = false;
      this.editedItem = {};
      this.errors = [];
    },
    showModal(item) {
      this.instrumentQuery = null;
      this.processing = false;
      this.showmodal = true;
      this.submitted = false;
      this.errors = [];
      this.editedItem = item ? {
        ...item, 
        exchange: (item.exchange || {}).id ?? null, 
        currency: (item.currency || {}).id ?? null,
        instrument: { id: item.instrument.id, type: item.instrument.type, name: item.instrument.name + ' [' + item.instrument.type + ']'}
      }
      : 
      {
        exchange: null,
        currency: null,
        instrument: null
      };
      this.editedItemId = item ? item.id : null;
    },
    handleSubmit() {
      
      this.submitted = true;
      this.errors = [];
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.saving = true;
        const dto = {...this.editedItem, id: this.editedItemId, instrument: this.editedItem.instrument.id };
        const req = !this.editedItemId ? http.post('api/products', dto) : http.put('api/products', dto);
        req.then(() => {
            this.saving = false;
          this.hideModal();
          this.load();
        })
        .catch(error => {
          this.saving = false;
          this.errors = parseErrors(error);
        });
      }
    },
    confirmDelete(item) {
      Swal.fire({
        title: this.$t("common.areYouSure"),
        text: this.$t("common.youCannotRevertOp"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t("common.yesDelete")
      }).then(result => {
        if (result.value) {
         this.handleDelete(item);
        }
      });
    },
    handleDelete(item) {
     http.delete(`api/products/${item.id}`).then(() => {
        this.hideModal();
          Swal.fire(this.$t("common.deleted"), this.$t("common.recWasDeleted"), "success");
          this.load();
      })
      .catch(error => {
        this.errors= parseErrors(error);
      });
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="row">
      <!-- Right Sidebar -->
      <div class="col-12">
        <div class="email-leftbar card cardc8 p-0">
          <div class="card-content m-3">
            <b-form-group
              class="mt-2"
              :label="$t('pages.products.keyword')"
              label-for="type"
            >
              <div class="search-box">
                <div class="position-relative">
                  <input type="text" class="form-control rounded" v-model="keyword" :placeholder="$t('pages.products.keyword')"  id="keywords"/>
                  <i class="mdi mdi-magnify search-icon"></i>
                </div>
              </div>
            </b-form-group>

            <b-form-group
              class="mt-2"
              :label="$t('pages.products.name')"
              label-for="name"
            >
              <div class="search-box">
                <div class="position-relative">
                  <input type="text" class="form-control rounded" v-model="name" :placeholder="$t('pages.products.name')"  id="name"/>
                  <i class="mdi mdi-magnify search-icon"></i>
                </div>
              </div>
            </b-form-group>

            <b-form-group
              class="mt-2"
              :label="$t('pages.products.instrumentName')"
              label-for="instrumentName"
            >
              <div class="search-box">
                <div class="position-relative">
                  <input type="text" class="form-control rounded" v-model="instrumentName" :placeholder="$t('pages.products.instrumentName')"  id="instrumentName"/>
                  <i class="mdi mdi-magnify search-icon"></i>
                </div>
              </div>
            </b-form-group>

          

            <b-form-group
              class="mt-2"
              :label="$t('pages.products.currency')"
              label-for="selectedCurrency"
            >
              <select class="form-control" v-model="selectedCurrency" id="selectedCurrency">
                <option :value="null">--- Select currency ---</option>
                <option v-for="t in currencies" :value="t.id" :key="t.id">
                  {{t.displayName}}
                </option>
              </select>
            </b-form-group>
            
            <b-form-group
              class="mt-2"
              :label="$t('pages.products.exchange')"
              label-for="selectedExchange"
            >
              <select class="form-control" v-model="selectedExchange" id="selectedExchange">
                <option :value="null">--- Select exchange ---</option>
                <option v-for="t in exchanges" :value="t.id" :key="t.id">
                  {{t.displayName}}
                </option>
              </select>
            </b-form-group>

            <b-form-group
              class="mt-2"
              label-for="svValue"
            >
            <template v-slot:label>
                {{$t('pages.products.key')}}&nbsp;<i v-if="keysLoading" class="fa fa-spin fa-spinner"></i>
              </template>
              <select class="form-control" v-model="selectedKey" id="svValue">
                <option :value="null">--- Select key ---</option>
                <option v-for="t in keys" :value="t" :key="t">
                {{t}}
                </option>
              </select>
            </b-form-group>

            <b-form-group
              v-if="selectedKey"
              class="mt-2"
              :label="$t('pages.products.value')"
              label-for="value"
            >
              <div class="search-box">
                <div class="position-relative">
                  <input type="text" class="form-control rounded" v-model="selectedKeyValue" :placeholder="$t('pages.products.value')" id="value"/>
                  <i class="mdi mdi-magnify search-icon"></i>
                </div>
              </div>
            </b-form-group>
            <hr/>
            <b-form-group>
              <button class="btn btn-block btn-secondary" @click="applyFilters">{{$t("common.applyFilters")}}</button>
            </b-form-group>
        </div>
      </div>


        <div class="email-rightbar mb-2">
          <div class="card cardc8">
            <div class="card-content m-3">
            <div class="form-group ">
             <b-button variant="secondary" class="mb-2" @click="showModal()">{{$t("common.newRecord")}}</b-button>
            </div> 

            <div class="table-responsive" style="position: relative; min-height:300px">
              <table
                class="table table-centered nowrap"
                style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead>
                  <tr>
                    <th style="width: 30px;max-width:30px;"></th>
                    <th>{{$t("tables.products.columns.name")}}</th>
                    <th>{{$t("tables.products.columns.instrument")}}</th>
                    <th>{{$t("tables.products.columns.currency")}}</th>
                    <th>{{$t("tables.products.columns.exchange")}}</th>
                    <th v-if="selectedKey">{{selectedKey}}</th>
                    <th>{{$t("tables.products.columns.description")}}</th>
                    <th style="width: 30px;max-width:30px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="items.length === 0 && isBusy !== true">
                    <td colspan="100">
                      <span class="text-muted">{{$t("common.noRecordsFound")}}</span>
                    </td>
                  </tr>
                  <tr v-for="(item, index) in items" :key="index">
                   <td>
                      <a
                        v-if="!item.isSystem"
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        v-b-tooltip.hover
                        @click="showModal(item)"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                    </td> 
                   
                    <td>
                      <span class="mr-1">{{item.name}}</span>
                      <em class="text-info small" v-if="item.name !== item.displayName">[{{item.displayName}}]</em>
                    </td>
                   
                    <td>{{item.instrument.name}}</td>
                    <td>{{item.currency.name}}</td>
                    <td>{{item.exchange.name}}</td>
                     <td v-if="selectedKey">
                      {{item.systemValues[selectedKey]}}
                      <em class="text-muted small" v-if="!item.systemValues[selectedKey]">{{$t("common.noValue")}}</em>
                    </td>  
                    <td>{{item.description}}</td>

                    <td>
                      <a
                        v-if="!item.isSystem"
                        href="javascript:void(0);"
                        class="mr-3 text-danger"
                        :disabled="saving"
                        @click="confirmDelete(item)"
                        title="Delete"
                      >
                        <i class="fa fa-times font-size-18"></i>
                      </a>
                    </td> 
                  </tr>
                </tbody>
              </table>
              <div style="position:absolute; left:0; right:0; top:0; bottom:0; background-color: rgba(255,255,255,0.5); text-align: center; padding-top:10%;"
                v-if="isBusy">
                <span>
                  <i class="fa fa-spin fa-spinner" style="font-size:3rem"></i>
                </span>
              </div>
            </div>
          </div>
           <div class="row justify-content-md-between align-items-md-center">
            <div class="col-xl-7">{{$t('common.totalRecords')}} {{totalItems}}</div>
            <!-- end col-->
            <div class="col-xl-5">
              <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  @input="load"
                ></b-pagination>
              </div>
            </div>
            <!-- end col-->
          </div>
        </div>
        </div>
      </div>
    </div>

   <!-- Modal -->
    <b-modal
    
      v-model="showmodal"
      :title="editedItemId ? $t('common.editRecord') :  $t('common.newRecord')"
      title-class="text-dark font-18"
      size="lg"
      hide-footer
    >
      <form @submit.prevent="handleSubmit" autocomplete="off">
         <b-alert
          variant="danger"
          class="mt-3"
          v-if="errors.length > 0"
          show
          dismissible
          >
          <ul>
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </b-alert>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="name">{{$t('forms.product.name')}} [*]</label>
              <input
                id="name"
                v-model="editedItem.name"
                type="text"
                class="form-control"
                placeholder=""
                :class="{ 'is-invalid': submitted && $v.editedItem.name.$error }"
              />
              <div
                v-if="submitted && !$v.editedItem.name.required"
                class="invalid-feedback"
              >{{$t('forms.product.name-required')}}</div>
            </div>
          </div>
           <div class="col-md-6">
              <div class="form-group">
              <label for="displayName">{{$t('forms.product.displayName')}} [*]</label>
              <input
                id="displayName"
                v-model="editedItem.displayName"
                type="text"
                class="form-control"
                placeholder=""
                :class="{ 'is-invalid': submitted && $v.editedItem.displayName.$error }"
              />
              <div
                v-if="submitted && !$v.editedItem.displayName.required"
                class="invalid-feedback"
              >{{$t('forms.product.displayName-required')}}</div>
            </div>
          </div>
        </div>
        <div class="row">
           <div class="col-md-12">
             <div class="form-group" >
              <label for="instrument">{{$t('forms.product.instrument')}}</label>
              <div class="input-group" v-if="editedItem.instrument">
                <input class="form-control" readonly v-model="editedItem.instrument.name"/>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" @click="clearInstrument">
                    <i class="fa fa-times"/>
                  </button>
                </div>
              </div>
              <div v-if="!editedItem.instrument" style="position: relative"  >
                <vue-typeahead-bootstrap
                :class="{ 'is-invalid': submitted && $v.editedItem.instrument.$error }"
                  v-model="instrumentQuery"
                  :ieCloseFix="false"
                  :data="instruments"
                  :serializer="item => item.name"
                  @hit="editedItem.instrument = $event"
                  placeholder="Search instrument"
                 
                  @input="debounceSearchInstrument"
                />
                <div
                  v-if="submitted && !$v.editedItem.instrument.required"
                  class="invalid-feedback"
                >{{$t('forms.product.instrument-required')}}</div>
                  <span v-if="instrumentsLoading" style="position:absolute; top: 7px; right: 7px;z-index:999999"><i class="fa fa-spin fa-spinner"/></span>
               </div>
               
            </div>
          </div>
        </div>
        <div class="row">
          
          <div class="col-md-6">
            <div class="form-group">
              <label for="currency">{{$t('forms.product.currency')}}</label>
              <select class="form-control" v-model="editedItem.currency" id="currency"
              :class="{ 'is-invalid': submitted && $v.editedItem.currency.$error }"
              >
                <option :value="null">--- Select currency ---</option>
                <option v-for="t in currencies" :value="t.id" :key="t.id">
                {{t.displayName}}
                </option>
              </select>
              <div
                v-if="submitted && !$v.editedItem.currency.required"
                class="invalid-feedback"
              >{{$t('forms.product.currency-required')}}</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="exchange">{{$t('forms.product.exchange')}}</label>
              <select class="form-control" v-model="editedItem.exchange" id="exchange"
               :class="{ 'is-invalid': submitted && $v.editedItem.exchange.$error }"
               >
                <option :value="null">--- Select exchange ---</option>
                <option v-for="t in exchanges" :value="t.id" :key="t.id"
                
                 >
                {{t.displayName}}
                </option>
              </select>
               <div
                v-if="submitted && !$v.editedItem.exchange.required"
                class="invalid-feedback"
              >{{$t('forms.product.exchange-required')}}</div>
            </div>
          </div>
        </div>
        
         <div class="form-group">
          <label for="description">{{$t('forms.product.description')}}</label>
          <textarea
            id="description"
            v-model="editedItem.description"
            class="form-control"
           />
        
        </div>

           <!-- <td>{{item.name}}</td>
                    <td>{{item.displayName}}</td>
                    <td>{{item.issueDate | moment("ddd, MMM Do YYYY") }}</td>
                    <td>{{item.countryOfIssue}}</td>
                    <td>{{item.issuer}}</td>
                    <td>{{item.priceIncrement}}</td> -->
        <h6>System Values</h6>
        <system-values :values="editedItem.systemValues" @change="o => editedItem.systemValues = o"></system-values>
        <div>
          <b-button class="ml-1 float-left" @click="hideModal">{{$t('forms.product.closeButton-title')}}</b-button>
          <button type="submit" class="btn btn-success float-right" :disabled="saving">
            <i class="fa fa-spin fa-spinner" v-if="saving"></i>
            {{$t('forms.product.saveButton-title')}}
          </button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>